import type { HTMLAttributes } from 'react';
import clsx from 'clsx';

import type { TextView, TextColor } from './types';

import styles from './Typography.module.css';

interface NativeTextProps {
  color?: TextColor;
  view?: TextView;
  size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'inherit';
  pre?: boolean;
  block?: boolean;
  title?: string;
  italic?: boolean;
}

export type TextProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> &
  NativeTextProps;

export const Text = ({
  className,
  view,
  size,
  color,
  pre,
  block,
  title,
  italic,
  onClick,
  children,
}: TextProps) => {
  const classes = clsx(
    styles.text,
    size ? styles[`size_${size}`] : null,
    view ? styles[`view_${view}`] : null,
    color ? styles[`color_${color}`] : null,
    pre ? styles['pre'] : null,
    block ? styles['block'] : null,
    italic ? styles['italic'] : null,
    className
  );
  return (
    <div className={classes} title={title} onClick={onClick}>
      {children}
    </div>
  );
};
