import clsx from 'clsx';

import styles from './Action.module.css';

type Icon = 'upload' | 'logout';

interface ActionProps {
  className?: string;
  icon: Icon;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export const Action = ({
  className,
  icon,
  disabled,
  loading,
  onClick,
}: ActionProps) => {
  const iconClass = icon ? styles[`icon_${icon}`] : null;
  const loadingClass = loading ? styles.loading : null;
  const disabledClass = disabled ? styles.disabled : null;
  return (
    <span
      className={clsx(
        styles.container,
        iconClass,
        loadingClass,
        disabledClass,
        className
      )}
      onClick={onClick}
    ></span>
  );
};
