import type { LabelHTMLAttributes } from 'react';
import clsx from 'clsx';

import type { TextView, TextColor } from './types';

import styles from './Typography.module.css';

interface NativeLabelProps {
  color?: TextColor;
  view?: TextView;
}

export type LabelProps = Omit<LabelHTMLAttributes<HTMLLabelElement>, 'color'> &
  NativeLabelProps;

export const Label = ({
  className,
  color = 'secondary',
  view = 'primary-small',
  children,
}: LabelProps) => {
  const classes = clsx(
    view ? styles[`view_${view}`] : null,
    color ? styles[`color_${color}`] : null,
    className
  );
  return <label className={classes}>{children}</label>;
};
